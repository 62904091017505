*, ::before, ::after {
 --tw-border-spacing-x: 0;
 --tw-border-spacing-y: 0;
 --tw-translate-x: 0;
 --tw-translate-y: 0;
 --tw-rotate: 0;
 --tw-skew-x: 0;
 --tw-skew-y: 0;
 --tw-scale-x: 1;
 --tw-scale-y: 1;
 --tw-pan-x:  ;
 --tw-pan-y:  ;
 --tw-pinch-zoom:  ;
 --tw-scroll-snap-strictness: proximity;
 --tw-gradient-from-position:  ;
 --tw-gradient-via-position:  ;
 --tw-gradient-to-position:  ;
 --tw-ordinal:  ;
 --tw-slashed-zero:  ;
 --tw-numeric-figure:  ;
 --tw-numeric-spacing:  ;
 --tw-numeric-fraction:  ;
 --tw-ring-inset:  ;
 --tw-ring-offset-width: 0px;
 --tw-ring-offset-color: #fff;
 --tw-ring-color: rgb(59 130 246 / 0.5);
 --tw-ring-offset-shadow: 0 0 #0000;
 --tw-ring-shadow: 0 0 #0000;
 --tw-shadow: 0 0 #0000;
 --tw-shadow-colored: 0 0 #0000;
 --tw-blur:  ;
 --tw-brightness:  ;
 --tw-contrast:  ;
 --tw-grayscale:  ;
 --tw-hue-rotate:  ;
 --tw-invert:  ;
 --tw-saturate:  ;
 --tw-sepia:  ;
 --tw-drop-shadow:  ;
 --tw-backdrop-blur:  ;
 --tw-backdrop-brightness:  ;
 --tw-backdrop-contrast:  ;
 --tw-backdrop-grayscale:  ;
 --tw-backdrop-hue-rotate:  ;
 --tw-backdrop-invert:  ;
 --tw-backdrop-opacity:  ;
 --tw-backdrop-saturate:  ;
 --tw-backdrop-sepia:  ;
}
::backdrop {
 --tw-border-spacing-x: 0;
 --tw-border-spacing-y: 0;
 --tw-translate-x: 0;
 --tw-translate-y: 0;
 --tw-rotate: 0;
 --tw-skew-x: 0;
 --tw-skew-y: 0;
 --tw-scale-x: 1;
 --tw-scale-y: 1;
 --tw-pan-x:  ;
 --tw-pan-y:  ;
 --tw-pinch-zoom:  ;
 --tw-scroll-snap-strictness: proximity;
 --tw-gradient-from-position:  ;
 --tw-gradient-via-position:  ;
 --tw-gradient-to-position:  ;
 --tw-ordinal:  ;
 --tw-slashed-zero:  ;
 --tw-numeric-figure:  ;
 --tw-numeric-spacing:  ;
 --tw-numeric-fraction:  ;
 --tw-ring-inset:  ;
 --tw-ring-offset-width: 0px;
 --tw-ring-offset-color: #fff;
 --tw-ring-color: rgb(59 130 246 / 0.5);
 --tw-ring-offset-shadow: 0 0 #0000;
 --tw-ring-shadow: 0 0 #0000;
 --tw-shadow: 0 0 #0000;
 --tw-shadow-colored: 0 0 #0000;
 --tw-blur:  ;
 --tw-brightness:  ;
 --tw-contrast:  ;
 --tw-grayscale:  ;
 --tw-hue-rotate:  ;
 --tw-invert:  ;
 --tw-saturate:  ;
 --tw-sepia:  ;
 --tw-drop-shadow:  ;
 --tw-backdrop-blur:  ;
 --tw-backdrop-brightness:  ;
 --tw-backdrop-contrast:  ;
 --tw-backdrop-grayscale:  ;
 --tw-backdrop-hue-rotate:  ;
 --tw-backdrop-invert:  ;
 --tw-backdrop-opacity:  ;
 --tw-backdrop-saturate:  ;
 --tw-backdrop-sepia:  ;
}
.container {
 width: 100%;
}
@media (min-width: 640px) {
 .container {
  max-width: 640px;
 }
}
@media (min-width: 768px) {
 .container {
  max-width: 768px;
 }
}
@media (min-width: 1024px) {
 .container {
  max-width: 1024px;
 }
}
@media (min-width: 1280px) {
 .container {
  max-width: 1280px;
 }
}
@media (min-width: 1536px) {
 .container {
  max-width: 1536px;
 }
}
.fixed {
 position: fixed !important;
}
.absolute {
 position: absolute !important;
}
.relative {
 position: relative !important;
}
.sticky {
 position: sticky !important;
}
.m-2 {
 margin: 0.5rem !important;
}
.m-4 {
 margin: 1rem !important;
}
.m-auto {
 margin: auto !important;
}
.my-auto {
 margin-top: auto !important;
 margin-bottom: auto !important;
}
.ml-4 {
 margin-left: 1rem !important;
}
.ml-5 {
 margin-left: 1.25rem !important;
}
.block {
 display: block !important;
}
.flex {
 display: flex !important;
}
.h-16 {
 height: 4rem !important;
}
.h-6 {
 height: 1.5rem !important;
}
.w-full {
 width: 100% !important;
}
.max-w-6xl {
 max-width: 72rem !important;
}
.max-w-lg {
 max-width: 32rem !important;
}
.flex-grow {
 flex-grow: 1 !important;
}
.grow {
 flex-grow: 1 !important;
}
.flex-row {
 flex-direction: row !important;
}
.flex-col {
 flex-direction: column !important;
}
.place-content-center {
 place-content: center !important;
}
.place-content-between {
 place-content: space-between !important;
}
.justify-start {
 justify-content: flex-start !important;
}
.justify-center {
 justify-content: center !important;
}
.gap-1 {
 gap: 0.25rem !important;
}
.gap-2 {
 gap: 0.5rem !important;
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
 --tw-space-x-reverse: 0 !important;
 margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
 margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}
.space-y-1 > :not([hidden]) ~ :not([hidden]) {
 --tw-space-y-reverse: 0 !important;
 margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
 margin-bottom: calc(0.25rem * var(--tw-space-y-reverse)) !important;
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
 --tw-space-y-reverse: 0 !important;
 margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
 margin-bottom: calc(0.5rem * var(--tw-space-y-reverse)) !important;
}
.space-y-3 > :not([hidden]) ~ :not([hidden]) {
 --tw-space-y-reverse: 0 !important;
 margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
 margin-bottom: calc(0.75rem * var(--tw-space-y-reverse)) !important;
}
.space-y-4 > :not([hidden]) ~ :not([hidden]) {
 --tw-space-y-reverse: 0 !important;
 margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
 margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important;
}
.rounded-lg {
 border-radius: 0.5rem !important;
}
.rounded-t-lg {
 border-top-left-radius: 0.5rem !important;
 border-top-right-radius: 0.5rem !important;
}
.border {
 border-width: 1px !important;
}
.bg-transparent {
 background-color: transparent !important;
}
.bg-white {
 --tw-bg-opacity: 1 !important;
 background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}
.p-0 {
 padding: 0px !important;
}
.p-16 {
 padding: 4rem !important;
}
.p-3 {
 padding: 0.75rem !important;
}
.px-4 {
 padding-left: 1rem !important;
 padding-right: 1rem !important;
}
.px-8 {
 padding-left: 2rem !important;
 padding-right: 2rem !important;
}
.py-2 {
 padding-top: 0.5rem !important;
 padding-bottom: 0.5rem !important;
}
.text-center {
 text-align: center !important;
}
.align-middle {
 vertical-align: middle !important;
}
.font-\[500\] {
 font-weight: 500 !important;
}
.underline {
 text-decoration-line: underline !important;
}
.opacity-100 {
 opacity: 1 !important;
}
.opacity-20 {
 opacity: 0.2 !important;
}
.shadow {
 --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
 --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color) !important;
 box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-\[0_0_0_3px_rgba\(32\2c 125\2c 57\2c 0\.3\)\] {
 --tw-shadow: 0 0 0 3px rgba(32,125,57,0.3) !important;
 --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color) !important;
 box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.filter {
 filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.duration-200 {
 transition-duration: 200ms !important;
}
.ease-in {
 transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}

.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  margin-left: -20px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 20px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 20px;
}
.hover\:cursor-pointer:hover {
 cursor: pointer !important;
}
.hover\:shadow-xl:hover {
 --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1) !important;
 --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color) !important;
 box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.card,.cardWithHover{margin:auto;background-color:#fff}.hoveredBorder{box-shadow:0 0 0 3px rgba(32,125,57,.3)}.cardHover,.cardWithHover{transition:.3s;box-shadow:0 0 40px -12px rgba(0,0,0,.3)}.cardHover:hover,.cardWithHover:hover{box-shadow:0 0 70px -12.125px rgba(0,0,0,.3),0 0 0 3px rgba(32,125,57,.3)}html{overflow-y:scroll !important;background-color:rgba(200,200,200,.47)}@supports(overflow-y: overlay){html{overflow-y:overlay;scrollbar-gutter:auto}}
::-webkit-scrollbar{width:20px}::-webkit-scrollbar-track{background-color:rgba(0,0,0,0)}::-webkit-scrollbar-thumb{background-color:#d6dee1;border-radius:20px;border:6px solid rgba(0,0,0,0);background-clip:content-box}::-webkit-scrollbar-thumb:hover{background-color:#a8bbbf}
.noSelect{-webkit-touch-callout:none;-webkit-user-select:none;-moz-user-select:none;user-select:none}.center{display:flex;margin:auto}
.p8{padding-top:8px;padding-bottom:8px}
*, ::before, ::after {

    --tw-border-spacing-x: 0;

    --tw-border-spacing-y: 0;

    --tw-translate-x: 0;

    --tw-translate-y: 0;

    --tw-rotate: 0;

    --tw-skew-x: 0;

    --tw-skew-y: 0;

    --tw-scale-x: 1;

    --tw-scale-y: 1;

    --tw-pan-x:  ;

    --tw-pan-y:  ;

    --tw-pinch-zoom:  ;

    --tw-scroll-snap-strictness: proximity;

    --tw-gradient-from-position:  ;

    --tw-gradient-via-position:  ;

    --tw-gradient-to-position:  ;

    --tw-ordinal:  ;

    --tw-slashed-zero:  ;

    --tw-numeric-figure:  ;

    --tw-numeric-spacing:  ;

    --tw-numeric-fraction:  ;

    --tw-ring-inset:  ;

    --tw-ring-offset-width: 0px;

    --tw-ring-offset-color: #fff;

    --tw-ring-color: rgb(59 130 246 / 0.5);

    --tw-ring-offset-shadow: 0 0 #0000;

    --tw-ring-shadow: 0 0 #0000;

    --tw-shadow: 0 0 #0000;

    --tw-shadow-colored: 0 0 #0000;

    --tw-blur:  ;

    --tw-brightness:  ;

    --tw-contrast:  ;

    --tw-grayscale:  ;

    --tw-hue-rotate:  ;

    --tw-invert:  ;

    --tw-saturate:  ;

    --tw-sepia:  ;

    --tw-drop-shadow:  ;

    --tw-backdrop-blur:  ;

    --tw-backdrop-brightness:  ;

    --tw-backdrop-contrast:  ;

    --tw-backdrop-grayscale:  ;

    --tw-backdrop-hue-rotate:  ;

    --tw-backdrop-invert:  ;

    --tw-backdrop-opacity:  ;

    --tw-backdrop-saturate:  ;

    --tw-backdrop-sepia:  
}

::backdrop {

    --tw-border-spacing-x: 0;

    --tw-border-spacing-y: 0;

    --tw-translate-x: 0;

    --tw-translate-y: 0;

    --tw-rotate: 0;

    --tw-skew-x: 0;

    --tw-skew-y: 0;

    --tw-scale-x: 1;

    --tw-scale-y: 1;

    --tw-pan-x:  ;

    --tw-pan-y:  ;

    --tw-pinch-zoom:  ;

    --tw-scroll-snap-strictness: proximity;

    --tw-gradient-from-position:  ;

    --tw-gradient-via-position:  ;

    --tw-gradient-to-position:  ;

    --tw-ordinal:  ;

    --tw-slashed-zero:  ;

    --tw-numeric-figure:  ;

    --tw-numeric-spacing:  ;

    --tw-numeric-fraction:  ;

    --tw-ring-inset:  ;

    --tw-ring-offset-width: 0px;

    --tw-ring-offset-color: #fff;

    --tw-ring-color: rgb(59 130 246 / 0.5);

    --tw-ring-offset-shadow: 0 0 #0000;

    --tw-ring-shadow: 0 0 #0000;

    --tw-shadow: 0 0 #0000;

    --tw-shadow-colored: 0 0 #0000;

    --tw-blur:  ;

    --tw-brightness:  ;

    --tw-contrast:  ;

    --tw-grayscale:  ;

    --tw-hue-rotate:  ;

    --tw-invert:  ;

    --tw-saturate:  ;

    --tw-sepia:  ;

    --tw-drop-shadow:  ;

    --tw-backdrop-blur:  ;

    --tw-backdrop-brightness:  ;

    --tw-backdrop-contrast:  ;

    --tw-backdrop-grayscale:  ;

    --tw-backdrop-hue-rotate:  ;

    --tw-backdrop-invert:  ;

    --tw-backdrop-opacity:  ;

    --tw-backdrop-saturate:  ;

    --tw-backdrop-sepia:  
}

.container {

    width: 100%
}

@media (min-width: 640px) {

    .container {

        max-width: 640px
    }
}

@media (min-width: 768px) {

    .container {

        max-width: 768px
    }
}

@media (min-width: 1024px) {

    .container {

        max-width: 1024px
    }
}

@media (min-width: 1280px) {

    .container {

        max-width: 1280px
    }
}

@media (min-width: 1536px) {

    .container {

        max-width: 1536px
    }
}

.fixed {

    position: fixed !important
}

.absolute {

    position: absolute !important
}

.relative {

    position: relative !important
}

.sticky {

    position: sticky !important
}

.m-2 {

    margin: 0.5rem !important
}

.m-4 {

    margin: 1rem !important
}

.m-auto {

    margin: auto !important
}

.my-auto {

    margin-top: auto !important;

    margin-bottom: auto !important
}

.ml-4 {

    margin-left: 1rem !important
}

.ml-5 {

    margin-left: 1.25rem !important
}

.block {

    display: block !important
}

.flex {

    display: flex !important
}

.h-16 {

    height: 4rem !important
}

.h-6 {

    height: 1.5rem !important
}

.w-full {

    width: 100% !important
}

.max-w-6xl {

    max-width: 72rem !important
}

.max-w-lg {

    max-width: 32rem !important
}

.flex-grow {

    flex-grow: 1 !important
}

.grow {

    flex-grow: 1 !important
}

.flex-row {

    flex-direction: row !important
}

.flex-col {

    flex-direction: column !important
}

.place-content-center {

    place-content: center !important
}

.place-content-between {

    place-content: space-between !important
}

.justify-start {

    justify-content: flex-start !important
}

.justify-center {

    justify-content: center !important
}

.gap-1 {

    gap: 0.25rem !important
}

.gap-2 {

    gap: 0.5rem !important
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-x-reverse: 0 !important;

    margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;

    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0 !important;

    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;

    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse)) !important
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0 !important;

    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;

    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse)) !important
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0 !important;

    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;

    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse)) !important
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0 !important;

    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;

    margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important
}

.rounded-lg {

    border-radius: 0.5rem !important
}

.rounded-t-lg {

    border-top-left-radius: 0.5rem !important;

    border-top-right-radius: 0.5rem !important
}

.border {

    border-width: 1px !important
}

.bg-transparent {

    background-color: transparent !important
}

.bg-white {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important
}

.p-0 {

    padding: 0px !important
}

.p-16 {

    padding: 4rem !important
}

.p-3 {

    padding: 0.75rem !important
}

.px-4 {

    padding-left: 1rem !important;

    padding-right: 1rem !important
}

.px-8 {

    padding-left: 2rem !important;

    padding-right: 2rem !important
}

.py-2 {

    padding-top: 0.5rem !important;

    padding-bottom: 0.5rem !important
}

.text-center {

    text-align: center !important
}

.align-middle {

    vertical-align: middle !important
}

.font-\[500\] {

    font-weight: 500 !important
}

.underline {

    text-decoration-line: underline !important
}

.opacity-100 {

    opacity: 1 !important
}

.opacity-20 {

    opacity: 0.2 !important
}

.shadow {

    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;

    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color) !important;

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.shadow-\[0_0_0_3px_rgba\(32\2c 125\2c 57\2c 0\.3\)\] {

    --tw-shadow: 0 0 0 3px rgba(32,125,57,0.3) !important;

    --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color) !important;

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.filter {

    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important
}

.duration-200 {

    transition-duration: 200ms !important
}

.ease-in {

    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important
}

.hover\:cursor-pointer:hover {

    cursor: pointer !important
}

.hover\:shadow-xl:hover {

    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1) !important;

    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color) !important;

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

