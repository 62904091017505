@tailwind base;
@tailwind components;
@tailwind utilities;

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -20px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 20px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 20px;
}
