.card {
  margin: auto;
  background-color: rgba(255, 255, 255, 1.0);
}

.hoveredBorder {
  box-shadow: 0 0 0 3px rgba(32, 125, 57, 0.3);
}

.cardHover {
  transition: 0.3s;

  box-shadow: 0 0 40px -12px rgba(0, 0, 0, 0.3);
  &:hover {
    box-shadow: 0 0 70px -12.125px rgba(0, 0, 0, 0.3), 0 0 0 3px rgba(32, 125, 57, 0.3);
  }
}

.cardWithHover {
  @extend .card;
  @extend .cardHover;
}

html {
  overflow-y: scroll !important;
  background-color: rgba(200, 200, 200, 0.47);
}

@supports (overflow-y: overlay) {
  html {
    overflow-y: overlay;
    scrollbar-gutter: auto;
  }
}